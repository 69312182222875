@import "src/_mixin.scss";

.homepageHeader {
  @include container;
  position: relative;
  padding: 0;
  display: flex;
  flex-wrap: wrap;

  @include media('mobile') {
    position: static;
    padding: 0;
  }

  &__mainInfoContainer {
    @include container;
    display: flex;
    flex-flow: row;
    flex: 2 1;

    @include media('mobile') {
      display: block;
      margin-bottom: 10px;
      order: -3;
    }
  }

  &__titleCountersContainer {
    flex: 2 1;
  }

  &__reportLinkImage {
    width: 100%;
    max-width: 300px;
  }

  &__reportLinkImage {
    width: 100%;
    max-width: 300px;
  }

  &__mainTitle {
    @include poppins_font(42px, 56px, 700, $text-dark-grey);
    letter-spacing: 1px;
    margin: 0 0 20px;

    @include media('mobile') {
      @include font_params(24px, 32px);
      margin-bottom: 15px;
    }
  }

  &__registeredUsersCount {
    @include lato_font(19px, 28px, 400, $text-grey);
    margin: 0 0 25px;

    @include media('mobile') {
      @include font_params(14px, 24px, 400, $text-dark-grey);
      margin-bottom: 35px;

      b {
        white-space: nowrap;
      }
      > div {
        display: inline;
      }
    }
  }

  &__registeredUsersStar {
    vertical-align: text-bottom;
    @include media('mobile') {
      vertical-align: bottom;
    }
  }

  &__serviceSearchWrapper {
    max-width: 560px;
    padding-right: 20px;
    @include media('tablet') {
      padding-right: 0;
      margin-bottom: 20px;
      &.sticky {
        z-index: 1000;
        left: 90px;
      }
    }
  }

  &__activityFeedContainer {
    flex: 1 1;
    // position: absolute;
    // top: 0;
    // right: 15px;
    max-width: 300px;
    width: 100%;
    margin-top: -40px;
    padding-right: 15px;

    @include media(975px) {
      width: 32vw;
    }

    @include media('mobile') {
      margin-top: 0;
      position: initial;
      width: 100%;
      max-width: unset;
      order: -1;
      margin-bottom: 20px;
      padding-right: 0;
    }

    // &_withPromoVideo {
    //   top: 220px;
    // }
  }

  &__activityFeed {
    @include media('mobile') {
      padding: 0 25px;
    }
  }

  & &__feedItems {
    max-height: 200px;
  }

  &__mainNavigation {
    @include container;
    display: flex;
    flex-flow: row nowrap;
    margin-bottom: 75px;
    width: 100%;

    @include media('mobile') {
      padding: 0;
      margin-bottom: 50px;
      order: -2;
    }

    @include min_media('medium') {
      margin-top: 1.5rem;
    }
  }

  &__navigationContainer {
    flex: 2 1;
  }

  &__featuredProviders {
    @include container;
    margin-bottom: 100px;

    @include media('mobile') {
      margin-bottom: 50px;
      padding: 0;
    }
  }

  &__promoImageDesktop {
    display: block;
    margin-bottom: 20px;
    > img {
      width: 100%;
      height: auto;
    }
    @include media('mobile') {
      display: none;
    }
  }
  &__promoImageMobile {
    display: none;
    > img {
      width: 100%;
      height: auto;
    }
    @include media('mobile') {
      display: block;
      margin-bottom: 35px;
    }
  }
}
