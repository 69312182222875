@import "src/_mixin.scss";

.androidBanner {
  display: none;
  align-items: center;
  justify-content: space-between;
  max-height: 0;
  overflow: hidden;
  padding: 0;
  background-color: $early-dawn;
  transition: max-height 0.2s;

  &_shown {
    max-height: 100px;
    padding: 15px;

    @include media('mobile') {
      display: flex;
    }
  }

  &__content {
    margin-right: auto;
    margin-left: 20px;
  }
}
