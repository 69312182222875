@import "src/_mixin.scss";

.articleList {
  display: grid;
  grid-row-gap: 2.5rem;
  grid-template-columns: 100%;
  max-width: 100%;

  @include min_media('medium') {
    grid-template-columns: 1fr 1fr 1fr;
    grid-column-gap: 1.25rem;
  }
}
