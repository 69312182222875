@import "src/_mixin.scss";

.serviceValues {
  display: flex;
  flex-flow: row nowrap;
  justify-content: space-between;

  @include media('mobile') {
    flex-flow: column;
  }

  &__item {
    flex: 1;

    &:nth-child(2) {
      margin-right: 40px;
      margin-left: 40px;

      @include media('mobile') {
        margin-left: 0;
        margin-right: 0;
      }
    }

    @include media('mobile') {
      margin-bottom: 30px;

      &:last-child {
        margin-bottom: 0;
      }
    }
  }

  &__title {
    @include poppins_font(24px, 40px, 700, $text-dark-grey);
    margin-bottom: 15px;
    letter-spacing: 0.6px;

    @include media('mobile') {
      font-size: 18px;
      margin-bottom: 4px;
    }
  }

  &__description {
    @include lato_font(16px, 24px, 400, $text-dark-grey);
    margin: 0;
  }
}
