@import "src/_mixin.scss";

.publicBanner {
  cursor: pointer;
  background-color: $cta;
  background-repeat: no-repeat;
  background-size: contain;
  background-position: left center;
  height: 92px;
  border-radius: 8px;
  box-shadow: 0 4px 4px rgba(0, 0, 0, 0.25);
  display: flex;
  align-items: center;

  // fixes conflict with .focus_ring_hidden
  &:focus {
    box-shadow: 0 4px 4px rgba(0, 0, 0, 0.25) !important;
  }
}

.publicBanner_contentless {
  background-size: cover;
}

.publicBanner__text {
  margin-left: 170px;
  max-width: 120px;
  font-size: 12px;
  line-height: 18px;
  @include min_media('medium') {
    margin-left: 304px;
    margin-right: 0;
    font-size: 16px;
    line-height: 24px;
    max-width: none;
  }
}

.publicBanner__arrow {
  width: 36px;
  height: 36px;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  background-color: $white;
  margin-left: auto;
  margin-right: 20px;
  display: none;
  @include min_media('medium') {
    display: flex;
  }
}
