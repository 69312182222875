@import "src/_mixin.scss";

.HomepageBanners {
  @include container;
  min-height: 112px;

  .HomepageBanners {
    &__slider {
      margin: 0 -5px;
    }

    &__itemWrapper {
      padding: 10px 5px;
    }
  }

  .slick-dots {
    bottom: -1rem;

    li {
      height: auto;
    }

    button {
      width: 9px;
      height: 9px;
      border-radius: 50%;
      background-color: $text-disabled;

      &::before {
        content: none;
      }
    }

    .slick-active button {
      background-color: $text-dark-grey;
    }
  }
}
