@import "src/_mixin.scss";

.articleCard {
  border-radius: 5px;
  position: relative;
  overflow: hidden;
  transition: 200ms;

  &:hover {
    box-shadow: 0 4px 14px rgba(0, 0, 0, 0.15);
  }
}

.articleCard__image {
  width: 100%;
  height: 204px;
  object-fit: cover;
  vertical-align: top;
}

.articleCard__description {
  margin-top: 1rem;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 3;
  overflow: hidden;
}

.articleCard__body {
  padding: 1.5rem 1rem;
  border: 1px solid $alto;
  border-top-width: 0;
  border-bottom-left-radius: 5px;
  border-bottom-right-radius: 5px;
}

.articleCard__clickable:not(:visited) {
  color: currentColor;

  &:after {
    content: '';
    position: absolute;
    left: 0;
    top: 0;
    bottom: 0;
    right: 0;
  }
}

.articleCard__clickable:visited {
  color: revert;
}

.articleCard__bottom {
  margin-top: 2.5rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.articleCard__date {
  color: $dark-grey;
}
