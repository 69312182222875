@import "src/_mixin.scss";

.categoriesSlider {
  position: relative;
  max-width: 1440px;
  margin: 0 auto;
  overflow: hidden;

  @include media('mobile') {
    padding-right: 25px;
    overflow: hidden;
    margin-left: 25px;
  }

  &__list {
    @include container;
    padding-top: 10px;
    padding-bottom: 10px;
    white-space: nowrap;

    @include media('mobile') {
      margin: 0;
      padding: 0;
    }

    &::before,
    &::after {
      content: '';
      position: absolute;
      top: 0;
      left: 0;
      width: 230px;
      height: 100%;
      background: linear-gradient(90deg, rgba(255,255,255,1), rgba(255,255,255,0));
      z-index: 1;

      @include media('mobile') {
        display: none;
      }
    }

    &::after {
      left: auto;
      right: 0;
      background: linear-gradient(270deg, rgba(255,255,255,1), rgba(255,255,255,0));
    }
  }

  .slick-list {
    overflow: initial;
  }

  .slick-arrow {
    position: absolute;
    width: 100px;
    height: 150px;
    padding: 0;
    background: none;
    border: none;
    z-index: 2;
    outline: none;

    @include media('mobile') {
      display: none !important;
    }

    &:before {
      display: block;
      content: '';
      position: absolute;
      top: 0;
      bottom: 0;
      right: 0;
      left: 0;
      width: 45px;
      height: 45px;
      margin: auto;
      background-color: $cta;
      border-radius: 50%;
      transition: transform .2s;
    }

    &:after {
      display: block;
      content: '';
      position: absolute;
      top: 0;
      bottom: 0;
      right: 0;
      left: 0;
      width: 10px;
      height: 10px;
      margin: auto;
      border: 2px solid $dark-grey;
      border-width: 2px 2px 0 0;
      transform: rotate(-135deg) translate(-2px, 2px);
    }
  }

  .slick-prev {
    left: -100px;
  }

  .slick-next {
    right: -100px;

    &:after {
      transform: rotate(45deg) translate(-2px, 2px);
    }
  }
}

.categorySlide {
  display: inline-block;
  width: 100%;

  &__link {
    display: flex;
    flex-flow: column;
    position: relative;
    max-width: 300px;
    height: 150px;
    margin-right: 15px;
    justify-content: center;
    align-items: center;
    background-color: $grey-settings;
    transition: transform .2s, box-shadow .2s;
    overflow: hidden;

    &:hover {
      transform: scale(1.07);
      box-shadow: 0 2px 6px 0 rgba(0,0,0,.1)
    }

    &::before {
      content: '';
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      z-index: 1;
      background: linear-gradient(
        0deg,
        rgba(0, 0, 0, .75) 0,
        rgba(0, 0, 0, 0) 100%
      );
    }
  }

  &__coverImg {
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    z-index: 0;
    object-fit: cover;
  }

  &__label {
    @include poppins_font(16px, 22px, 700, #fff);
    z-index: 1;
    white-space: normal;
    text-align: center;
  }

  &__requestsCount {
    @include lato_font(15px, 18px, 400, transparentize($white, .3));
    z-index: 1;
  }

}
