@import "src/_mixin.scss";

.adminMessage {
  color: #fff;
  opacity: 1;
  transform: translateY(0);
  transition: opacity .2s, transform .2s;

  &_loading {
    opacity: 0;
    transform: translateY(50px);
  }

  &__title {
    @include poppins_font(24px, 36px, 400, #fff);
    margin-bottom: 50px;

    @include media('mobile') {
      @include font_params(18px, 25px, 500);
      margin-bottom: 35px;
    }
  }

  &__text {
    margin-bottom: 50px;

    @include media('mobile') {
      @include font_params(14px, 24px);
      margin-bottom: 35px;
    }

    ul {
      list-style: initial;
      padding-left: 16px;
    }

    ol {
      padding-left: 12px;

      li {
        padding-left: 3px;
      }
    }

    a {
      color: #fff;
      text-decoration: underline;

      &:hover {
        color: #fff;
        text-decoration: none;
      }
    }

    & > * {
      color: white;
    }
  }

  &__media {
    display: block;
    width: 100%;
    margin: 0 0 75px;

    @include media('mobile') {
      margin-bottom: 35px;
    }
  }

  &__image {
    max-width: 100%;
  }

  &__author {
    display: flex;
    margin-bottom: 75px;
    align-items: center;

    @include media('mobile') {
      @include font_params(14px, 24px);
    }
  }

  &__authorAvatar {
    margin-right: 15px;
    background-color: $cta;
    border-radius: 50%;
  }

  &__authorInfo {
    margin-bottom: 0;
  }

  &__authorName,
  &__authorRole {
    display: block;
  }

  &__spinnerWrap {
    min-height: 100%;
    position: relative;
  }

  &__spinner {
    @include spinner('~common/icons/spinner_white.svg', 50px);
    opacity: .5;
  }

  // Overrides

  .language-markup {
    @include border($silver-chalice);
    @include font_params(16px, 25px);
    color: white;
    padding: 20px;
    background: $background-grey;
    border-radius: $global-border-radius;
    white-space: normal;
  }
}
