@import "src/_mixin.scss";

.homepage {
  .homepage__homepageBanners {
    margin-top: 20px;
    @include min_media('medium') {
      margin-top: 30px;
    }
  }

  &__header {
    margin-top: 100px;

    @include media('mobile') {
      margin-top: 35px;
      margin-bottom: 50px;
    }
  }

  &__subtitle {
    @include poppins_font(24px, 36px, 500, $text-dark-grey);
    margin-bottom: 40px;
    text-align: left;

    @include media('mobile') {
      @include font_params(18px, 32px);
      margin-bottom: 35px;
    }

    &_popularCategories {
      @include container;
      margin-bottom: 35px;
    }

    &_featuredProviders {
      display: none;

      @include media('mobile') {
        @include container;
        display: block;
        margin-bottom: 15px;
      }
    }

    &_activityFeed {
      display: none;

      @include media('mobile') {
        @include container;
        display: block;
        margin-bottom: 35px;
      }
    }
  }

  &__serviceValues {
    @include container;
    margin-bottom: 75px;
  }

  &__popularCategories {
    margin-bottom: 100px;

    @include media('mobile') {
      margin-bottom: 70px;
    }
  }

  &__blog {
    @include container;
    margin-bottom: 100px;
  }

  &__blogCards {
    align-items: flex-start;
  }

  &__clientAppBanner {
    @include container;
    margin-bottom: 50px;
  }
}
