@import "src/_mixin.scss";

.serviceSearch {
  &__form {
    display: flex;
  }

  &__inputWrap {
    flex: 1;
    position: relative;
  }

  &__submit {
    @include button_cta;

    width: 50px;
    margin-left: -2px;
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
    background-image: url('../../common/icons/search.svg');
    background-position: center;
    background-repeat: no-repeat;
    background-size: 15px;

    &:focus {
      box-shadow: none;
      border-color: $cta;
    }

    &_inHeader {
      @include media('mobile') {
        display: none;
      }
    }
  }
}
