@import "src/_mixin.scss";

.noscroll {
  overflow-y: hidden;
}

.adminMessageOverlay {
  position: fixed;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
  background-color: rgba(65, 65, 65, 0.95);
  z-index: -1;
  opacity: 0;

  &_html,
  &_body {
    // Used on ssr
    background-color: rgb(65, 65, 65);
    padding-top: 0;
  }

  &_open {
    opacity: 1;
    z-index: 1000;
    transition: transform 0.4s, opacity 0.4s;
  }

  &_closing {
    transform: scale(0);
    transform-origin: calc(100% - 230px) 50px;
    opacity: 0;
  }

  &_ssr {
    position: static;
    min-height: 100vh;
    margin-top: -50px;
    opacity: 1;
    z-index: 0;
  }

  &__header {
    height: $desktop-header-height;
    background-color: $dark-grey;

    @include media('mobile') {
      height: $mobile-header-height;
    }
  }

  &__logoContainer {
    display: inline-block;
    height: 100%;
    padding: 15px 25px;
    background: $background-cta;

    @include media('mobile') {
      width: 80px;
      padding: 13px 15px;
    }
  }

  &__logo {
    height: 100%;
    vertical-align: middle;
  }

  &__close {
    @include button_close(25px, #fff, 2px);
    position: absolute;
    top: 100px;
    right: 100px;
  }

  &_ssr &__close {
    display: none;
  }

  &__wrapper {
    height: 100%;
    overflow-y: scroll;
  }

  &__content {
    max-width: 620px;
    margin: 150px auto 0;
    transform: translateY(20px);
    opacity: 0;
    transition: transform 0.2s, opacity 0.2s;

    @include media('mobile') {
      padding: 0 35px;
    }
  }

  &_open &__content,
  &_ssr &__content {
    transform: translateY(0);
    opacity: 1;
  }
}
