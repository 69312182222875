@import "src/_mixin.scss";

.clientAppBanner {
  position: relative;
  box-sizing: border-box;
  padding: 25px;
  height: 410px;
  border-radius: 4px;
  background: rgb(255, 248, 233);
  background: linear-gradient(140deg, rgba(255, 248, 233, 1) 0%, rgba(255, 255, 255, 1) 100%);
  background-size: contain;

  @include min_media('medium') {
    padding: 50px;
    width: 940px;
    height: 317px;
  }

  &__caption {
    position: relative;
    z-index: 10;
    margin-bottom: 20px;

    @include poppins_font(20px);
    @include min_media('medium') {
      width: 490px;

      @include poppins_font(30px);
    }
    > strong {
      font-weight: 600;

      @include min_media('medium') {
        display: block;
      }
    }
  }
  &__buttonsWrapper {
    position: relative;
    z-index: 10;

    @include min_media('medium') {
      position: absolute;
      bottom: 30px;
      left: 50px;
      width: 490px;
    }
  }
  .clientAppBanner__button {
    display: block;
    padding-right: 25px;
    padding-left: 25px;
    text-transform: none;

    @include min_media('medium') {
      display: inline-block;
      margin-right: 10px;
    }
    > svg {
      margin-right: 8px;
      vertical-align: sub;
    }
  }
  &__mobilePhones {
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 214px;
    background-image: url('./img/client-app-banner-mobile.png');
    background-position: center bottom;
    background-size: 409px 214px;
    background-repeat: no-repeat;

    @include min_media('medium') {
      right: -80px;
      left: auto;
      width: 577px;
      height: 302px;
      background-size: contain;
    }
  }
}
