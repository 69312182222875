@import "src/_mixin.scss";

.serviceSuggest {
  &__input {
    @include border;
    width: 100%;
    padding: 10px 15px 11px;
    border-radius: 4px 0 0 4px;
    border-right-width: 0;
    transition: border-color 0.2s;

    &:focus {
      border-color: $cta;
    }

    &_inHeader {
      line-height: 17px;

      @include media('mobile') {
        padding-left: 35px !important;
        background-image: url('../../common/icons/search.svg');
        background-position: left 12px center;
        background-repeat: no-repeat;
        border-radius: 4px;
        border-right-width: 1px;

        &:focus {
          padding: 15px 14px 14px 40px;
          border: 0;
        }
      }
    }
  }

  &__suggestionContent {
    padding: 10px 20px;
    cursor: pointer;

    &:hover,
    &_highlighted {
      background-color: $grey-settings;
    }
  }
}

.react-autosuggest {
  &__container {
    height: 100%;
  }

  &__suggestions-container {
    position: absolute;
    width: 100%;
    max-height: 400px;
    z-index: 5;
  }

  &__suggestions-list {
    margin: 0;
    padding: 0;
    border: 1px solid $border-dark;
    border-radius: 0 0 4px 4px;
    background-color: #fff;
    color: $dark-grey;
    text-align: left;
  }
}
